import React, { useState, useEffect, useRef } from 'react';

const ChatMessage = ({ message, isOutgoing }) => (
  <div className={`flex flex-col ${isOutgoing ? 'items-end' : 'items-start'} mb-4`}>
    {message.photoUrl ? (
      <img src={message.photoUrl} alt="Photo" className="max-w-xs rounded-xl" />
    ) : (
      <div
        className={`relative rounded-xl px-3 py-2 max-w-[70%] break-words ${
          isOutgoing ? 'bg-blue-800 text-white' : 'bg-gray-500  text-white'
        } ${isOutgoing ? 'chat-bubble-outgoing' : 'chat-bubble-incoming'}`}
      >
        {message.text}
      </div>
    )}
    <div className={`text-xs mt-1 ${isOutgoing ? 'text-gray-400' : 'text-gray-500'}`}>
      {new Date(message.timestamp).toLocaleString([], { hour: 'numeric', minute: 'numeric', hour12: true })}
    </div>
  </div>
);

const Chat = () => {
  const [messages, setMessages] = useState([
    { text: 'Hey', timestamp: Date.now() - 86400000, isOutgoing: false },
    { text: '??', timestamp: Date.now() - 3600000, isOutgoing: true },
    { text: "Hey I'm never on here Hey I'm never on here Hey I'm never on here", timestamp: Date.now(), isOutgoing: false },
  ]);

  const [inputText, setInputText] = useState('');
  const chatContainerRef = useRef(null);

  useEffect(() => {
    // Use useEffect hook to perform side effects when messages state changes
    const chatContainer = chatContainerRef.current; // Get the current chat container element
    if (chatContainer) {
      const lastMessage = chatContainer.lastElementChild; // Get the last message element
      if (lastMessage) {
        lastMessage.scrollIntoView({ behavior: 'smooth' }); // Scroll to the last message smoothly
      }
    }
  }, [messages]); // Specify messages as a dependency array to trigger the effect when it changes

  const handleSendMessage = async () => {
    if (inputText.trim() !== '') {
      const newMessage = {
        text: inputText,
        timestamp: Date.now(),
        isOutgoing: true,
      };
      setMessages([...messages, newMessage]);
      setInputText('');
  
      try {
        const response = await fetch('/api/send-message', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            message: inputText,
            recipient: 'Xhua',
            sender: 'User',
          }),
        });
  
        if (response.ok) {
          const data = await response.json();
          const recipientResponse = data.response;
          const photoUrl = data.photoUrl;
  
          // Add the recipient's response message to the chat
          const recipientMessage = {
            text: recipientResponse,
            timestamp: Date.now(),
            isOutgoing: false,
          };
          setMessages([...messages, newMessage, recipientMessage]);
  
          // Add the photo message to the chat
          const photoMessage = {
            photoUrl: photoUrl,
            timestamp: Date.now(),
            isOutgoing: false,
          };
          setMessages([...messages, newMessage, recipientMessage, photoMessage]);
        } else {
          console.error('Failed to send message');
        }
      } catch (error) {
        console.error('Error sending message:', error);
      }
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSendMessage();
    }
  };

  const handleBackClick = () => {
    // Handle the back button click event
    // Navigate back to the contact list or perform any other desired action
  };

  return (
    <div className="flex flex-col min-h-screen bg-black">
      <div className="flex items-center justify-between p-3 border-b border-gray-800 sticky top-0 bg-black bg-opacity-40 backdrop-filter backdrop-blur-2xl z-10">
        <div className="flex items-center">
          <button
            className="text-white focus:outline-none mr-3"
            onClick={handleBackClick}
          >
            Back
          </button>
          <img
            src="https://via.placeholder.com/40"
            alt="Profile"
            className="w-8 h-8 rounded-full mr-3"
          />
          <div className="text-white">xhua</div>
        </div>
      </div>
      <div className="flex-1 overflow-y-auto p-3" ref={chatContainerRef}>
        {messages.map((message, index) => (
          <ChatMessage key={index} message={message} isOutgoing={message.isOutgoing} />
        ))}
      </div>
      <div className="flex p-3 border-t border-gray-800 sticky bottom-0 bg-black bg-opacity-40 backdrop-filter backdrop-blur-2xl">
        <input
          type="text"
          className="flex-1 px-3 py-2 bg-black bg-opacity-0 text-white border border-gray-800 rounded-l-xl focus:outline-none backdrop-filter backdrop-blur-2xl"
          placeholder="Type a message"
          value={inputText}
          onChange={(e) => setInputText(e.target.value)}
          onKeyDown={handleKeyDown}
        />
        <button
          className="px-3 py-2 bg-black bg-opacity-0 text-white border border-gray-800 rounded-r-xl focus:outline-none backdrop-filter backdrop-blur-2xl"
          onClick={handleSendMessage}
        >
          Send
        </button>
      </div>
    </div>
  );
};

export default Chat;

